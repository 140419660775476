<template>
    <div class="geekqoo-search">
        <div class="search-box">
            <p class="item-label">关键词：</p>
            <div class="item-input">
                <input v-model="keyword" placeholder="请输入关键词" type="text" @keyup.13="search()">
                <van-icon name="search" @click="search()"/>
            </div>
        </div>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
import {getLocalStorage, setLocalStorage} from "@/utils/storage";

export default {
    name: "GeekQooSearch",
    mixins: [mixins_config],
    data() {
        return {
            keyword: ""
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.keyword) {
            this.keyword = this.$route.query.keyword
        }
    },
    methods: {
        search() {
            if(this.$route.name === "GoodsList"){
                this.$emit("search",this.keyword)
            }else{
                // 历史搜索加入localstorage
                let searchHistoryArr = [];
                if (getLocalStorage("search")) {
                    searchHistoryArr = getLocalStorage("search").split("|")
                    if (searchHistoryArr.indexOf(this.keyword) === -1) {
                        setLocalStorage("search", this.keyword + "|" + getLocalStorage("search"))
                    }
                } else {
                    setLocalStorage("search", this.keyword)
                }
                this.$router.push({
                    name: "Search",
                    query: {
                        keyword: this.keyword
                    }
                })
            }
            console.log(this.keyword)
        }
    }
}
</script>

<style lang="scss">
.geekqoo-search {
    width: 100%;

    .search-box {
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 45px;
        padding: 0 20px;
        background: rgba(#F96719, 1);

        .item-label {
            line-height: 28px;
            font-size: 10px;
            color: #FFFFFF;
        }

        .item-input {
            position: relative;
            margin-left: auto;
            width: 285px;
            height: 28px;

            input {
                width: 100%;
                height: 100%;
                border: none;
                background: rgba(#ffffff, 0.5);
                padding: 0 0 0 10px;
                font-size: 10px;
                color: #ffffff;
                border-radius: 2px;
            }

            i {
                display: block;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                font-size: 15px;
                color: #fff
            }
        }
    }
}
</style>