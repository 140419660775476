<template>
    <div class="home-page">
        <div class="banner">
            <GeekQooSearch></GeekQooSearch>
            <van-swipe :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in bannerList" :key="index">
                    <a :style="`background:url(${item.cover})`" class="banner-content"></a>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="tab-list">
            <div v-for="(item,index) in tabList.slice(0,10)" :key="index" class="tab-item"
                 @click="toCategoryList(index)">
                <img :src="item.icon?item.icon:app.emptyImg" draggable="false">
                <span>{{ item.title }}</span>
            </div>
        </div>
        <div class="recommend-list">
            <p class="item-title">精品推荐</p>
            <div class="item-list">
                <a v-for="(item,index) in goodsList.slice(0,20)" :key="index" @click="toDetail(item)">
                    <img :src="item.thumb">
                    <span class="van-ellipsis">{{ item.title }}</span>
                    <span class="van-ellipsis">{{ item.sub_title }}</span>
                    <span class="van-ellipsis red">￥{{ item.price }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
// 引入接口
import {BANNERS, GOODS_GROUP, GOODS_LIST} from "@/api/dataProvider.js"

export default {
    name: "Home",
    mixins: [mixins_config],
    components: {
        GeekQooSearch
    },
    data() {
        return {
            // banner图片
            bannerList: [],
            // 金刚区列表
            tabList: [],
            goodsList: []
        };
    },
    created() {
        this.app.tabActive = 0
    },
    mounted() {
        this.getBanner()
        this.getGoodsGroup()
        this.getGoodsList()
    },
    methods: {
        // 获取banner图片
        getBanner() {
            let that = this
            BANNERS({}, function (res) {
                console.log(res.data.data.data)
                that.bannerList = res.data.data.data
            })
        },
        // 获取商品分类
        getGoodsGroup() {
            let that = this;
            GOODS_GROUP({}, function (res) {
                if (res.data.code === 0) {
                    console.log(res.data.data)
                    that.tabList = res.data.data.group
                }
            })
        },
        // 获取商品列表
        getGoodsList() {
            let that = this
            GOODS_LIST(
                {
                    page: 1,
                    recommend: 1
                },
                function (res) {
                    if (res.data.code === 0) {
                        console.log(res.data.data.data)
                        that.goodsList = res.data.data.data
                    }
                }
            )
        },
        // 跳转分类列表
        toCategoryList(index) {
            this.$router.push({
                name: "CategoryList",
                query: {
                    active: index
                }
            })
        },
        // 跳转商品详情
        toDetail(item) {
            this.$router.push({
                name: "GoodDetail",
                params: {id: item.id}
            })
        }
    }
}
</script>

<style lang="scss">
.home-page {
    padding: 0 0 70px 0;

    .banner {
        position: relative;
        width: 100%;
        height: 174px;

        .van-swipe {
            width: 100%;
            height: 100%;

            .van-swipe-item {

                .banner-content {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat !important;
                    background-size: cover !important;
                    background-position: center !important;
                }
            }
        }

        .geekqoo-search {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            .search-box {
                background: rgba(#F96719, 0.5);
            }
        }
    }

    .tab-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 20px 0;
        overflow: hidden;

        .tab-item {
            width: 20%;
            margin-top: 20px;

            img {
                display: block;
                width: 52px;
                height: 52px;
                margin: 0 auto;
                border-radius: 50%;
                object-fit: cover;
            }

            span {
                display: block;
                margin: 10px 0 0 0;
                text-align: center;
                font-size: 10px;
                color: #2D2D2D;
            }
        }
    }

    .recommend-list {
        padding: 0 10px;
        .item-title {
            line-height: 28px;
            margin: 10px 0;
            padding-left: 15px;
            font-size: 14px;
            font-weight: bold;
            color: #2D2D2D;
            border-left: 7px solid #F96719;
        }

        .item-list {
            font-size: 0;

            a {
                display: inline-block;
                vertical-align: middle;
                width: 50%;
                padding: 10px;
                img{
                    width: 100%;
                    height: 90px;
                    object-fit: cover;
                }
            }
            span{
                display: block;
                margin-top: 10px;
                font-size: 12px;
                color: #333;
            }
            .red{
                color: #F54A4A;
            }
        }
    }
}
</style>